$brandPrimary: #3e1eff;
$brandSecondary: #02ecc8;

//$brandPrimary: rgb(random(255), random(255), random(255));
//$brandSecondary: rgb(random(255), random(255), random(255));

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');

body {
    font-family: 'Noto Sans JP', sans-serif;
    margin: 0;

    h1, h2, h3, h4 {
        margin: 5px 0;
    }
}
animated-background {
    position: fixed;
    z-index: -1;
    top: -100%; bottom: -100%; left: -100%; right: -100%;
    top: calc(max(100vh, 100vw) * -1); bottom: calc(max(100vh, 100vw) * -1); left: calc(max(100vh, 100vw) * -1); right: calc(max(100vh, 100vw) * -1);
    $opacity: 0.8;
    background: linear-gradient(
        94.23deg
        ,rgba($brandPrimary, $opacity) 25%, rgba($brandSecondary, $opacity) 75%);
    
    -webkit-animation:spin 34s linear infinite;
    -moz-animation:spin 34s linear infinite;
    animation:spin 34s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
body {

    page {
        height: 100vh;
        display: flex;
        align-content: center;
        justify-content: center;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
    }

    a {
        text-decoration: none;
    }
}
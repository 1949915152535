

    material-card {
        width: 400px;
        background: white;
        display: flex;
        padding: 20px;
        flex-direction: column;

        [data-role="image"] {
            
        }

        [data-role="header"] {
            padding: 15px 0;
        }

        [data-role="content"] {
            flex-grow: 1;
        }

        [data-role="footer"] {
            padding-top: 15px;
        }
    }
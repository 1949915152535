image-blur {
            margin: -21px; 
            width: calc(100% + 42px);



            display: inline-block;
            text-align: center;
            overflow: hidden;
            position:relative;
            height: auto;
            img {
                z-index: 2;
                filter: blur(0px);
            }
            img:first-child {
                position: absolute;
                top: -10px; left: -10px; right: -10px; bottom: -10px;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                filter: blur(10px);
                z-index: 0;
            }
        }
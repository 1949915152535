logo-grid {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);

    img[data-role="icon"] {
        width: 100%;
        height: 100%;

        object-fit: contain;
        &:before {
            content: "";
            display: block;
            height: 0;
            width: 0;
            padding-bottom: calc(9/16 * 100%);
        }
        
        transition: 500ms;
        &:not(:hover) {
            filter: grayscale(1);

            &[data-icon="github"] {
                filter: grayscale(1) brightness(200%);
            }
        }
    }
}
portfilio-card {
    max-width: 900px;
    margin: 50px 0;
    display: flex;
    height: min-content;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        max-width: 100%;

        [data-role="about"] {
            padding-top: 40px;
        }
    }

    @media only screen and (max-width: 700px) {
        

        [data-role="details"] {
            flex-direction: column;
            margin-top: 40px;
        }
        material-card {
            width: 100%;
        }
        [data-role="profile"] {
            text-align: center;
            min-width: 100%;
            margin: 0;
        }
    }

    [data-role="profile"] {
        text-align: center;
        min-width: 250px;
        z-index: 1;
        background: white;
        box-shadow: 1px 5px 5px rgba(170, 170, 170, 0.425);
        border: solid 1px rgba(170, 170, 170, 0.2);
        ImageBlur img {
            height: 200px;
        }
        margin: -50px 0;
    
        ul[data-role="contact-links"] {
            text-align: center;
            padding: 0;
            margin: 0;
            img[data-role="icon"] {
                margin: 0 5px;
                width: 30px;
            }
        }
    }
    
    h3 {
        display: inline-block;
        
        padding-right: 10px;

        background: linear-gradient(
            94.23deg,
            $brandPrimary 35%,
            $brandSecondary 95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    &::after {
        display: block;
        content: "";
        height: 2px;
        width: calc(100% + 10px);
        background: linear-gradient(
            94.23deg
            ,$brandPrimary 15%, $brandSecondary 85%);
    }
    }

    [data-role="details"] {
        background: white;
        margin-left: -15px;
        display: flex;
        box-shadow: 1px 5px 5px rgba(#666, 0.425);
        border: solid 1px rgba(#666, 0.2);
        padding: 20px;
        padding-left: 35px;

        [data-role="about"] {
            padding-right: 20px;
            flex-basis: 1;
        }

        [data-role="technologies"] {
            max-width: 200px;
            flex-basis: 1;

            logo-grid {
                margin-top: 16px;
            }
        }
    }
    
}